import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import styles from './tool.module.sass'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

const ClientAccessPage = () => (
   <Layout>
      <SEO title="RJ Client Access" />
      <div className={['page', styles.page__tool].join(' ')}>
         <div className={['page--header', styles.page__header].join(' ')}>
            <h1 className={['page--title', styles.page__title].join(' ')}>
               Raymond James Client Access
            </h1>
            {/* <p className={['page--subheader', styles.page__subheader].join(' ')}></p> */}
         </div>
         <div className={styles.tool__content}>
            <div className={styles.tool__intro}>
               <div className={styles.left}>
                  <p>
                     With a Raymond James Client Access account, you have{' '}
                     <b>convenient, secure access</b> to your RJ accounts. You
                     can <b>monitor your assets from anywhere</b>, and you’ll
                     have the ability to <b>upload and share documents</b> with
                     us.
                  </p>
                  <p>
                     Raymond James Client Access is an online service that
                     provides you with secure and convenient access to your
                     Raymond James accounts, statements, trade confirmations,
                     and tax reporting documents. Client Access allows you to
                     securely access your Raymond James account information from
                     your computer or mobile device.
                  </p>
               </div>
               <div className={styles.right}>
                  <OutboundLink
                     href="//clientaccess.rjf.com"
                     target="_blank"
                     rel="noopener noreferrer"
                     className="button button--primary button--large button--has-icon">
                     Continue to Your Dashboard{' '}
                     <FontAwesomeIcon icon={faLongArrowAltRight} />
                  </OutboundLink>
               </div>
            </div>

            <div className={styles.tool__disclaimer}>
               <p>
                  Raymond James Financial Services, Inc. provides the platform
                  "RJ Client Access" for Raymond James clients.
               </p>
            </div>
         </div>
      </div>
   </Layout>
)

export default ClientAccessPage
